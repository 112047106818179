import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import * as firebaseui from "firebaseui";

const config = {
  apiKey: "AIzaSyA2g6WmQuqx0xYgdKa_-qqEvjyWkslnUvY",
  authDomain: "assetdelivery-59d37.firebaseapp.com",
  databaseURL: "https://assetdelivery-59d37.firebaseio.com",
  projectId: "assetdelivery-59d37",
  storageBucket: "assetdelivery-59d37.appspot.com",
  messagingSenderId: "1051905815347",
  appId: "1:1051905815347:web:9679ffe381a0b6dd5f4446",
  measurementId: "G-4JSKKM412S",
};

class Firebase {
  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    this.Auth = firebase.auth;
    this.auth = firebase.auth();
    this.functions = firebase.app().functions("europe-west2");
    this.db = firebase.firestore();
    this.firestore = firebase.firestore;
    if (process.env.NODE_ENV !== "test") {
      this.ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(this.auth);
    }
    this.storage = firebase.storage().ref();
  }
}

export default Firebase;
